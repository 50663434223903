/* Section Title */
.title-sec .container-fluid{
  padding: 5% 10% 7%; 
  text-align: left;
}
.title-head{
  padding: 7% 0;
}
.title-subhead {
  font-weight: normal;
}
.title-btn-div{
  margin: 5% 0;
}
.title-img-div{
  position: relative;
}
.title-img {
  width: 100%;
  /* transform: rotate(15deg); */
  position: absolute;
  /* right: 0; */
}
@media (min-width: 1801px) {
  .title-img {
    margin-bottom: 5%;
  }
}
@media (max-width: 1800px) {
  .title-img {
    margin: 5% 0 0;
  }
}
@media (max-width: 1450px) {
  .title-img {
    margin: 10% 0 0;
  }
}
@media (max-width: 1250px) {
  .title-img {
    margin: 25% 0 0;
  }
}



/* Section Features */
.features-sec {
  position: relative;
}
.features-sec .newSec{
  padding-bottom: 0;
}
.features-box {
  padding: 4.5%;
}
.features-icons{
  padding-bottom: 1rem;
}
.features-head{
  font-size: 1.5rem;
}
.features-icn-color{
  color: #009e66;
}
.features-icn-color:hover{
  color: #03b979;
}
.feature-text {
  color: var(--secondary-text-color);
}


/* Section Press */
.press-sec{
  padding-bottom: 5%;
}
.press-img{
  width: 200px;
  margin: 20px 20px 20px;
}

/* Media Quary */
@media (max-width: 991px) {
  .title-sec {
    text-align: center;
  }
  .title-sec .container-fluid {
    text-align: center;
  }
  .title-img {
    transform: rotate(0deg);
    position: static;
    margin: 5% 0 0;
  }
}