/* Exercise Sections */
.warn-sec{
    width: fit-content;
    margin: 0 auto 20px;
    color: red;
}
/* .warn-sec h2{
  color: red;
}
.warn-sec p{
  color: var(--grey);
} */
.warn-sec hr {
  border: 0;
  border-bottom: 7px dotted red;
  /* border: var(--hl-color);
  border-style:solid; */
  /* width: 15%; */
  /* margin: 0 auto 0; */
}
#scrollBtn {
  display: block; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
}