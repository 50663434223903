/* --- MOBILE STYLES --- */

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing);
  
}

.aboutCard{
  max-width: 28rem;
  border-color: var(--secondary-text-color);
  margin: 0 auto;
  /* to have equal heights in price cards */
  height: 100%;
  background-color: inherit;
}

.card-img{
  width: 200px;
}

.card-div{
  margin: 0 0px 30px;
}

.aboutCard .card-title{
  color: var(--text-color);
}

.aboutCard .card-text{
  color: var(--secondary-text-color);
}

.about .card-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.about .btn{
  display: inline-block;
  margin: 0 auto;
  width: fit-content;
}
