
.resultTD-true-cell{
  color: #228B22;
}
.resultTD-false-cell{
  color: #ff0000;
}
.resultTD-warning-cell{
  color: orange;
}
.result-grade-text{
  color: red;
  text-decoration: underline overline #FF3028;
}

.diagram-component {
  width: 100%;
  height: 500px;
  border: solid 1px black;
  background-color: #DFBB9D;
}

.RL-timetable-div{
  padding-bottom: 3%;
}
.RL-timetable-mt{
  margin-top: 3%;
}
.RL-timetable{
  table-layout: fixed;
  width: 95%;
  margin: 0 auto;
}
.RL-timetable-top-row{
  width: 100px; 
  border-right: 1px solid;
}
.RL-timetable-left-col{
  border-top: 1px solid; 
  border-bottom: 1px solid; 
}
.RL-timetable-cell{
  border: 1px solid; 
}