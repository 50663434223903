@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

/* --- VARIABLES --- */
:root {
  --bg-color: #101010;
  --hl-color: #009e66;
  --text-color: #f3f3f3;
  --secondary-text-color: #9f9f9f;
  --grey: #9f9f9f76;
  --spacing: 50px;
  --sm-spacing: 30px;
}

/* --- ANIMATIONS --- */

@keyframes slideDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(1%);
    opacity: 1;
  }
}

/* --- Selection --- */
.imgUnselectable{
  pointer-events: none;
  user-select: none;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* --- RESETS --- */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  overflow-y: scroll; /* Show vertical scrollbar */
}

html,
body,
#root {
  height: 100%;
  background-color: var(--bg-color);
}

.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
  font-weight: 900;
}
p{
  margin: 0;
}

.textWhite{
  color: var(--text-color);
}

.textBlack{
  color: var(--bg-color);
}

form label{
  color: var(--secondary-text-color);
}

/* --- GLOBAL STYLES --- */

.img-round-corners{
  border-radius: 10%;
}
.img-circle{
  border-radius: 100%;
}

.display-inline{
  display: inline;
}

/* Section Footer */
.footer-sec {
  /* padding: 2% 0%; */
  height: 150px;
  line-height:150px;
}

.btn-delete-opacity{
  opacity: 0.7;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Spacing */
.big-mt{
  margin-top: 100px;
}

/* Sections */
.flexSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* margin-top: var(--spacing); */
}
.newSec{
  padding: 0.5% 10% 2.5%;
}
.color-sec {
  background-color: #00ADB5;
  color: #222831;
}
.colorSecondary-sec {
  background-color: #DFBB9D;
  color: #222831;
}
.white-sec {
  background-color: #f3f3f3;
  color: #222831;
}

/* Alignments */
.text-align-left{
  text-align: left;
}
.text-align-right{
  text-align: right;
}
.text-align-center{
  text-align: right;
}
.div-align-center{
  margin: 0 auto;
}
.vertical-align-center{
  vertical-align: middle;
}

/* other */
.noHover{
  pointer-events: none;
}
tr:hover{
  background-color: var(--hl-color);
  color: var(--bg-color);
}
::selection {
  background-color: var(--hl-color);
  color: var(--text-color);
}

/* text */
.appTitle{
  font-size: 3.5rem;
}
.secTitle{
  padding-top: 100px;
  font-size: 2.5rem;
}
.pageTitle {
  color: var(--text-color);
  font-size: 36px;
  border-bottom: 3px solid var(--hl-color);
  margin-bottom: var(--spacing);
}
.pageDescription {
  color: var(--grey);
  font-size: 14px;
}

.btn {
  padding: 12px var(--sm-spacing);
  border-radius: 20px;
  margin-top: 10px;
  cursor: pointer;;
}
.btnSmall {
  font-size: 14px;
}
.btnGreen:hover {
  background-color: var(--hl-color); /* #03b979; */
}
.btnWide{
  width: 100%;
}


/* --- MOBILE STYLES --- */

/* --- HEADER ---  */
header {
  padding: 0.5% 10% 2.5%;
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-top: var(--spacing);
}

.brand-img{
  width: 50px;
  position: relative;
}
.brand-img:hover{
  filter: invert(60%) sepia(87%) saturate(6584%) hue-rotate(144deg) brightness(98%) contrast(101%);
}

.nav-item {
  padding: 0 10px;
}
.nav-link {
  font-size: 1.2rem;
  font-weight: 300;
}

.links {
  color: var(--secondary-text-color);
  font-size: 1.2rem;
  font-weight: 300;
  display: none;
  position: absolute;
  top: 93px;
  right: 0px;
  background-color: var(--bg-color);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.links a {
  color: var(--secondary-text-color);
  font-size: 1.1rem;
  font-weight: 300;
  text-decoration: none;  
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: relative;
}
.links button {
  margin: 0 auto;
  padding: 20px 0;
}

.links a:hover {
  color: white;
}
.links button:hover {
  color: white;
}

.links.open {
  display: block;
  animation: slideDown 0.5s ease-in-out forwards;
}

.hidden-dropdown-toggle {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.openMenu,
.closeMenu {
  height: 20px;
  transition: transform 0.5s ease-in-out;
}

.closeMenu {
  transform: rotate(180deg);
  cursor: pointer;
}

.links .closed {
  background-color: transparent;
}

.vertical-divider{
  display: none;
}

.dropdown-item{
  width: 96%;
}

@media (max-width: 1149px) {
  .links {
    z-index: 100;
  }
}

/* --- MEDIA QUERIES --- */
@media (min-width: 1150px) {
  .links {
    display: flex;
    background-color: var(--bg-color);
    justify-content: flex-end;
    position: relative;
    top: auto;
    width: 0;
    left: 0;
    padding: 0 10px;
  }

  .links a {
    padding: 0px;
    margin-left: 3px;
  }

  .links button {
    padding: 0px;
    margin-left: 3px;
    margin-right: 15px;
  }

  .links a:not(:last-child) {
    margin-right: 15px;
  }

  .links.open {
    display: flex;
  }

  .hidden-dropdown-toggle {
    display: none;
  }

  .links a:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--hl-color);
    transition: width 0.3s ease-in-out;
  }

  .links a:hover:before {
    width: 100%;
  }

  .links a.active:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--hl-color);
    transition: width 0.3s ease-in-out;
    width: 100%;
  }

  .closed {
    background-color: transparent;
  }

  .vertical-divider{
    margin-right: 20px;
    display: flex;
  }

  .dropdown-item:hover {color: var(--hl-color);}

  .dropdownHover:hover .dropdown-menu {display: block; position: absolute;}

  .dropdownHover:hover .dropdown-toggle {color: white;}
}
